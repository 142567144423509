import React, { ReactElement } from "react";
import { Variables } from "@page-builder/ts/types";
import { useDomainContext } from "@hooks/useDomainContext";
import { extractUrl } from "@page-builder/utils";

export default function MainSectionBgPreload({
    variables = {},
    sectionIndex,
}: {
    variables?: Variables;
    sectionIndex: number;
}): ReactElement {
    const { visitDetails } = useDomainContext();
    const addPreloadBgImg = sectionIndex === 0;
    let src = "";

    if (addPreloadBgImg) {
        const bg = variables.backgroundImage;

        const isMobile = visitDetails.isMobile;
        if (typeof bg === "string") {
            src = bg;
        } else {
            if (isMobile) {
                src = bg.sm;
            } else {
                src = bg.md ?? bg.lg ?? bg.xl ?? "";
            }
        }
    }

    return (
        <>
            {addPreloadBgImg ? (
                <img
                    src={extractUrl(src)}
                    alt=""
                    style={{ display: "none" }}
                    width={0}
                    height={0}
                />
            ) : (
                <></>
            )}
        </>
    );
}
